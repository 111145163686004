body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

.homecard {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
}

.homecard:hover,
.homecard:focus,
.homecard:active {
  color: #ee7203;
  border-color: #ee7203;
}

.homecard h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.homecard p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.underline-on-hover {
  font-weight: bold;
  cursor: pointer;
}

.underline-on-hover:hover {
  text-decoration: underline;
}
